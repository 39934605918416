<script setup lang="ts">
import {
  DialogOverlay,
  type DialogOverlayProps,
  useForwardProps,
} from "radix-vue";

const props = defineProps<DialogOverlayProps>();
const forwarded = useForwardProps(props);
</script>

<template>
  <DialogOverlay
    v-bind="forwarded"
  >
    <div class="fixed inset-0 flex w-screen justify-center overflow-y-auto bg-zinc-950/25 p-2 focus:outline-none sm:px-6 sm:py-8 lg:px-8 lg:py-16" />
    <slot />
  </DialogOverlay>
</template>
