import { authParamName, redirectParamName } from "@/common/axshare/routing";
import { getRouteParam } from "@/router/utils";
import { ShortcutType } from "@/services/models/filesystem";

import { getPrototypeRedirectCommentInfo } from "ls/api/cloud/prototype";
import { useAccountService } from "ls/features/user/useAccountService";
import NotFound from "ls/pages/NotFound.vue";
import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import { useAuthGuard as addAuthGuard } from "./auth-guard";
import { toFilePreview } from "./builders";
import { folderParameterName, organizationParameterName, routeNames, screenParameterName, shortcutParameterName, workspaceParameterName } from "./constants";
import { redirectOnSuccessfulSignIn } from "./redirects";
import { createDiskoUrl } from "./utils";

const SignIn = () => import("ls/features/user/SignIn.vue");

const Manage = () => import("ls/features/workspaces/Manage.vue");
const WorkspaceView = () => import("ls/features/workspaces/WorkspaceView.vue");
const RecentsView = () => import("ls/features/recents/RecentsView.vue");

const FileView = () => import("ls/features/files/FileView.vue");

const Settings = () => import("ls/features/settings/Settings.vue");
const SettingsAccount = () => import("ls/features/settings/SettingsAccount.vue");
const SettingsNotifications = () => import("ls/features/settings/SettingsNotifications.vue");
const SettingsOrganization = () => import("ls/features/organizations/SettingsOrganization.vue");
const SettingsOrganizationMembers = () => import("ls/features/organizations/SettingsOrganizationMembers.vue");
const SettingsOrganizationAuthentication = () => import("ls/features/organizations/SettingsOrganizationAuthentication.vue");
const SettingsOrganizationAuthenticationSso = () => import("ls/features/organizations/SettingsOrganizationAuthenticationSso.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: routeNames.home,
    redirect: {
      name: routeNames.manage,
    },
  },
  {
    path: "/fs/manage",
    name: routeNames.manage,
    component: Manage,
    children: [
      {
        path: `:${workspaceParameterName}/:${folderParameterName}?`,
        name: routeNames.workspace,
        component: WorkspaceView,
        props: true,
      },
      {
        path: "/fs/recents",
        name: routeNames.recents,
        component: RecentsView,
        props: true,
      },
      {
        path: `/project/:${shortcutParameterName}`,
        alias: `/project/:${shortcutParameterName}/overview`,
        name: routeNames.fileOverview,
        component: FileView,
        props: true,
        children: [
          {
            name: routeNames.fileHistory,
            path: "history",
            component: FileView,
            props: true,
          },
          {
            name: routeNames.fileComments,
            path: "comments",
            components: {},
            async beforeEnter(to) {
              const shortcut = getRouteParam(to.params, "shortcut");
              if (!shortcut) return true;

              const projectOverview = { name: routeNames.fileOverview, params: { shortcut: shortcut.toLowerCase() } };
              try {
                const commentInfo = await getPrototypeRedirectCommentInfo(shortcut);

                if (!commentInfo || !commentInfo.IssueCode) {
                  return projectOverview;
                }

                if (commentInfo.ShortcutType === ShortcutType.Disko) {
                  const params = new URLSearchParams();
                  params.append("issueCode", commentInfo.IssueCode);
                  const url = createDiskoUrl(shortcut, {
                    params,
                  });
                  window.location.href = url;
                  return false;
                }

                if (!commentInfo.ShortPageId) {
                  return projectOverview;
                }

                return toFilePreview(shortcut, commentInfo.ShortPageId, { comment: commentInfo.IssueCode });
              } catch {
                return projectOverview;
              }
            },
          },
        ],
      },
      {
        name: routeNames.filePreview,
        path: `/project/:${shortcutParameterName}/preview/:${screenParameterName}?`,
        component: () => import("ls/features/file-preview/FilePreview.vue"),
        props: true,
      },
      {
        name: routeNames.fileInspect,
        path: `/project/:${shortcutParameterName}/inspect/:${screenParameterName}?`,
        component: () => import("ls/features/file-preview/FilePreview.vue"),
        props: true,
      },
      {
        name: routeNames.fileBuild,
        path: `/project/:${shortcutParameterName}/build/:${screenParameterName}?`,
        component: () => import("ls/features/clickthrough/ClickthroughBuild.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/settings",
    name: routeNames.settings,
    component: Settings,
    redirect: {
      name: routeNames.settingsProfile,
    },
    children: [
      {
        path: "account",
        name: routeNames.settingsProfile,
        component: SettingsAccount,
        props: true,
      },
      {
        path: "notifications",
        name: routeNames.settingsNotifications,
        component: SettingsNotifications,
        props: true,
      },
      {
        path: `/orgs/:${organizationParameterName}`,
        name: routeNames.settingsOrganization,
        props: true,
        redirect: {
          name: routeNames.settingsOrganizationGeneral,
        },
        children: [
          {
            path: "general",
            name: routeNames.settingsOrganizationGeneral,
            component: SettingsOrganization,
            props: true,
          },
          {
            path: "members",
            name: routeNames.settingsOrganizationMembers,
            component: SettingsOrganizationMembers,
            props: true,
          },
          {
            path: "authentication",
            name: routeNames.settingsOrganizationAuthentication,
            component: SettingsOrganizationAuthentication,
            props: true,
          },
          {
            path: "authentication/sso",
            name: routeNames.settingsOrganizationAuthenticationSso,
            component: SettingsOrganizationAuthenticationSso,
            props: true,
          },
        ],
      },

    ],
  },
  {
    path: "/login",
    name: routeNames.signIn,
    component: SignIn,
    meta: {
      requiresAuth: false,
    },
    async beforeEnter(to, _from) {
      const accountService = useAccountService();

      // we may be already authenticated from global auth guard
      // if that's the case just follow redirect
      if (accountService.isAuthenticated.value) {
        const location = redirectOnSuccessfulSignIn(to);
        return location;
      }

      // Prevent cycle redirects - if auth param presented then just return
      if (!to.query[authParamName]) {
        // If account service is available then use its login page
        const redirectParam = to.query[redirectParamName];
        const hasRequiredParams = !!redirectParam;
        if (!await accountService.isSameAsApiHost() || !hasRequiredParams) {
          const accountServiceLoginUrl = await accountService.getLoginFullUrl(to);
          window.location.href = accountServiceLoginUrl;
          return;
        }
      }

      // if account service is already in the error state,
      // then it's a bounce from a different route
      // and we don't have to check session again
      if (accountService.error.value) {
        return true;
      }
      // but when no error and session is valid
      // continue with redirect
      const isAuthenticated = await accountService.checkSession();
      if (isAuthenticated) {
        const location = redirectOnSuccessfulSignIn(to);
        return location;
      }
      return true;
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routeNames.notFound,
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

addAuthGuard(router);
