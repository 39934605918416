import type { FilesystemJs, OrganizationJs, ShortcutJs } from "@/generated/models";
import { isFilesystemJs, isOrganizationJs, isShortcutJs } from "ls/features/workspaces/utils";
import { folderParameterName, organizationParameterName, routeNames, screenParameterName, shortcutParameterName, workspaceParameterName } from "./constants";

export function toHome() {
  return {
    name: routeNames.home,
  };
}

export function toRecents() {
  return {
    name: routeNames.recents,
  };
}

export function toManage() {
  return {
    name: routeNames.manage,
  };
}

export function toWorkspace(workspace: FilesystemJs | string, options: { invite?: boolean; folderId?: string } = {}) {
  const workspaceId = isFilesystemJs(workspace) ? workspace.FilesystemId.toLowerCase() : workspace.toLowerCase();
  return {
    name: routeNames.workspace,
    params: {
      [workspaceParameterName]: workspaceId,
      [folderParameterName]: options.folderId,
    },
    query: { invite: options.invite ? 1 : undefined },
  };
}

function getShortcut(file: ShortcutJs | string) {
  return isShortcutJs(file) ? file.Shortcut.toLowerCase() : file.toLowerCase();
}

export function toFileOverview(file: ShortcutJs | string) {
  return {
    name: routeNames.fileOverview,
    params: { [shortcutParameterName]: getShortcut(file) },
  };
}

export function toFileComments(file: ShortcutJs | string) {
  return {
    name: routeNames.fileComments,
    params: {
      [shortcutParameterName]: getShortcut(file),
    },
  };
}

export function toFileHistory(file: ShortcutJs | string) {
  return {
    name: routeNames.fileHistory,
    params: {
      [shortcutParameterName]: getShortcut(file),
    },
  };
}

export function toFilePreview(file: ShortcutJs | string, screen?: string, options: { comment?: string } = {}) {
  return {
    name: routeNames.filePreview,
    params: {
      [shortcutParameterName]: getShortcut(file),
      [screenParameterName]: screen?.toLowerCase(),
    },
    query: { comment: options?.comment },
  };
}

export function toFileInspect(file: ShortcutJs | string, screen?: string) {
  return {
    name: routeNames.fileInspect,
    params: {
      [shortcutParameterName]: getShortcut(file),
      [screenParameterName]: screen?.toLowerCase(),
    },
  };
}

export function toFileBuild(file: ShortcutJs | string) {
  return {
    name: routeNames.fileBuild,
    params: {
      [shortcutParameterName]: getShortcut(file),
    },
  };
}

function getOrganization(org: OrganizationJs | string) {
  return isOrganizationJs(org) ? org.Id.toLowerCase() : org.toLowerCase();
}

export function toOrganizationMembers(organization: OrganizationJs | string) {
  return {
    name: routeNames.settingsOrganizationMembers,
    params: {
      [organizationParameterName]: getOrganization(organization),
    },
  };
}

export function toOrganizationSettings(organization: OrganizationJs | string) {
  return {
    name: routeNames.settingsOrganizationGeneral,
    params: {
      [organizationParameterName]: getOrganization(organization),
    },
  };
}
