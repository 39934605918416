<script setup lang="ts">
defineProps<SimpleButtonProps>();
</script>

<script lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { isObject } from "@/common/lib/guards";
import { cva, type VariantProps } from "class-variance-authority";

export interface SimpleButtonProps {
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  disabled?: boolean;
  active?: boolean;
  href?: RouteLocationRaw | string;
};

export type ButtonProps = VariantProps<typeof button>;

export const button = cva([
  "inline-flex items-center justify-center gap-x-1 whitespace-nowrap rounded leading-6 outline-none outline-offset-2 focus-visible:outline-1 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
], {
  variants: {
    variant: {
      primary: [
        "font-medium text-white",
        "bg-blue-600 data-[state=open]:bg-blue-800 dark:bg-blue-400 dark:data-[state=open]:bg-blue-500",
        "hover:bg-blue-800 dark:hover:bg-blue-500",
        "focus-visible:outline-indigo-600 dark:focus-visible:outline-slate-100",
      ],
      secondary: [
        "font-medium dark:text-slate-100",
        "border border-slate-300 dark:border-zinc-600",
        "bg-white data-[state=open]:bg-gray-200/40 dark:bg-zinc-900 dark:data-[state=open]:bg-gray-700/40",
        "hover:bg-gray-200/40 dark:hover:bg-gray-700/40",
        "focus-visible:outline-indigo-600 dark:focus-visible:outline-slate-100",
      ],
      destructive: [
        "font-medium text-white",
        "bg-red-600 data-[state=open]:bg-red-700 dark:data-[state=open]:bg-red-500",
        "hover:bg-red-700 dark:hover:bg-red-500",
        "focus-visible:outline-indigo-600 dark:focus-visible:outline-slate-100",
      ],
      ghost: [
        "font-normal dark:text-slate-100 dark:data-[active]:text-blue-300",
        "data-[state=open]:bg-gray-200/40 dark:data-[state=open]:bg-gray-700/40",
        "hover:bg-gray-200/40 dark:hover:bg-gray-700/40",
        "focus-visible:outline-indigo-600 dark:focus-visible:outline-slate-100",
      ],
      link: [
        "border-0 bg-transparent font-normal text-blue-600 hover:underline focus-visible:underline",
        "data-[state=open]:text-blue-600",
        "hover:text-blue-600",
        "focus-visible:outline-blue-600",
      ],
      "link-dark": [
        "border-0 bg-transparent font-normal text-black hover:underline focus-visible:underline",
        "data-[state=open]:text-black",
        "hover:text-black",
        "focus-visible:outline-black",
      ],
      "link-light": [
        "border-0 bg-transparent font-normal text-blue-300 hover:underline focus-visible:underline",
        "data-[state=open]:text-blue-300",
        "hover:text-blue-300",
        "focus-visible:outline-blue-300",
      ],
      purple: [
        "font-medium text-white",
        "dark:purple-[state=open]:bg-purple-500 bg-purple-600 data-[state=open]:bg-purple-800 dark:bg-purple-400",
        "hover:bg-purple-800 dark:hover:bg-purple-500",
        "focus-visible:outline-indigo-600 dark:focus-visible:outline-slate-100",
      ],
    },
    size: {
      small: "h-5 px-2 text-xs",
      medium: "h-7 px-2",
      large: "h-8 px-3.5",
      icon: "size-7 ",
      "icon-small": "size-4",
      auto: "",
    },
  },
  compoundVariants: [
    {
      size: ["icon", "icon-small"],
      variant: "ghost",
      class: "text-slate-600 hover:text-slate-800 focus-visible:text-slate-800 dark:hover:text-slate-100 dark:focus-visible:text-slate-100",
    },
  ],
  defaultVariants: {
    variant: "primary",
    size: "medium",
  },
});
</script>

<template>
  <RouterLink
    v-if="isObject(href)"
    :to="href"
    :data-active="active ? '' : undefined"
    :data-disabled="disabled ? '' : undefined"
    :class="button({ variant, size })"
  >
    <slot />
  </RouterLink>
  <a
    v-else-if="href"
    :href="href"
    :data-active="active ? '' : undefined"
    :data-disabled="disabled ? '' : undefined"
    :class="button({ variant, size })"
  >
    <slot />
  </a>
  <button
    v-else
    type="button"
    :disabled="disabled"
    :data-active="active ? '' : undefined"
    :data-disabled="disabled ? '' : undefined"
    :class="button({ variant, size })"
  >
    <slot />
  </button>
</template>
