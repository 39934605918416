import type { SsoResponse } from "@/generated/models";
import type { RequestOptions } from "ls/api/common/server";
import type { RouteLocationNormalized } from "vue-router";
import type { ChangeAccountInfoModel } from "./utils";
import { isString } from "@/common/general";
import { createSharedComposable } from "@vueuse/core";
import { computed, readonly, type Ref, ref } from "vue";
import { useAxureCloudAccountService } from "./useAxureCloudAccountService";

function useAccountServiceFn() {
  const axureCloudAccountService = useAxureCloudAccountService();

  const user = ref<SsoResponse>();
  const error: Ref<Error | undefined> = ref(undefined);
  const isLoading = ref(false);

  function setError(fetchError: unknown) {
    if (!fetchError) return;

    if (isString(fetchError)) {
      error.value = new Error(fetchError);
    } else if (fetchError instanceof Error) {
      error.value = fetchError;
    } else {
      error.value = new Error(fetchError as unknown as string);
    }
    return error.value;
  }

  function clearAuth() {
    user.value = undefined;
  }

  const isAuthenticated = computed(() => !!user.value?.success);

  async function login(email: string, password: string, redirect?: string) {
    try {
      await axureCloudAccountService.login(email, password, redirect);
    } catch (err) {
      setError(err);
      throw err;
    }
  }

  async function checkSession() {
    try {
      isLoading.value = true;
      user.value = await axureCloudAccountService.auth();
      return isAuthenticated.value;
    } catch (err) {
      setError(err);
      return false;
    } finally {
      isLoading.value = false;
    }
  }

  async function logout() {
    try {
      await axureCloudAccountService.logout();
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      clearAuth();
    }
  }

  async function updateUserProfileName(nickname: string) {
    if (user.value?.nickname === nickname) return;
    const response = await axureCloudAccountService.updateUserProfileName(nickname);
    if (response.success && user.value) user.value.nickname = nickname;
  }

  async function uploadUserProfileImg(file: File) {
    const response = await axureCloudAccountService.uploadUserProfileImg(file);
    if (response.success && response.imgUrl && user.value) user.value.profileImageUrl = response.imgUrl;
  }

  async function deleteUserProfileImg() {
    const response = await axureCloudAccountService.deleteUserProfileImg();
    if (response.success && user.value) user.value.profileImageUrl = "";
  }

  async function changeAccountInfo(model: ChangeAccountInfoModel) {
    const response = await axureCloudAccountService.changeAccountInfo(model);
    if (response.success && user.value && model.newEmail) user.value.userEmail = model.newEmail;
  }

  async function forgotPassword(email: string, target: string, redirect?: string) {
    try {
      return await axureCloudAccountService.forgotPassword(email, target, redirect);
    } catch (err) {
      setError(err);
      throw err;
    }
  }

  async function getTimeZones({ signal }: RequestOptions = {}) {
    return await axureCloudAccountService.getTimeZones({ signal });
  }

  async function setUserTimeZone(timeZoneId?: string, { signal }: RequestOptions = {}) {
    return await axureCloudAccountService.setUserTimeZone(timeZoneId, { signal });
  }

  async function isSameAsApiHost() {
    return await axureCloudAccountService.isSameAsApiHost();
  }

  async function getLoginFullUrl(to?: RouteLocationNormalized) {
    return await axureCloudAccountService.getLoginFullUrl(to);
  }

  return {
    user: readonly(user),
    isAuthenticated,
    isLoading: readonly(isLoading),
    error: readonly(error),
    checkSession,
    login,
    logout,
    updateUserProfileName,
    uploadUserProfileImg,
    deleteUserProfileImg,
    changeAccountInfo,
    forgotPassword,
    getTimeZones,
    setUserTimeZone,
    isSameAsApiHost,
    getLoginFullUrl,
  };
}

export const useAccountService = createSharedComposable(useAccountServiceFn);
