export const workspaceParameterName = "workspaceId";
export const folderParameterName = "folderId";
export const organizationParameterName = "organizationId";
export const redirectParameterName = "redirect";
export const shortcutParameterName = "shortcut";
export const screenParameterName = "screen";
export const objectParameterName = "object";
export const documentNodeParameterName = "node";
export const issueCodeParameterName = "issueCode";
export const commentIdParameterName = "commentId";

export const routeNames = {
  home: "home",
  signIn: "signIn",
  manage: "manage",
  recents: "recents",
  workspace: "workspace",
  fileOverview: "fileOverview",
  fileComments: "fileComments",
  fileHistory: "fileHistory",
  filePreview: "filePreview",
  fileInspect: "fileInspect",
  fileBuild: "fileBuild",
  settings: "settings",
  settingsProfile: "settingsProfile",
  settingsNotifications: "settingsNotifications",
  settingsOrganization: "settingsOrganization",
  settingsOrganizationGeneral: "settingsOrganizationGeneral",
  settingsOrganizationMembers: "settingsOrganizationMembers",
  settingsOrganizationAuthentication: "settingsOrganizationAuthentication",
  settingsOrganizationAuthenticationSso: "settingsOrganizationAuthenticationSso",
  notFound: "notFound",
} as const;
