<script setup lang="ts">
import TextLinkEmailSupport from "ls/components/TextLinkEmailSupport.vue";
import { useAuth } from "ls/features/user/useAuth";
import { routeNames } from "ls/router/constants";

const { isAuthenticated } = useAuth();
</script>

<template>
  <div>
    <h2>This page doesn't exist</h2>
    <div>
      <p>
        Please check the provided URL.<br /> If you need some help, email us at <TextLinkEmailSupport />.
      </p>
    </div>
    <div>
      You may also
      <RouterLink
        class="underline"
        :to="isAuthenticated ? { name: routeNames.home } : { name: routeNames.signIn }"
      >
        {{ isAuthenticated ? 'go back to Workspaces' : 'go to Sign In page' }}
      </RouterLink>.
    </div>
  </div>
</template>
