<script setup lang="ts">
import { isObject } from "@/common/lib/guards";
import { computed } from "vue";
import { type RouteLocationRaw, RouterLink } from "vue-router";

const props = defineProps<{
  href?: RouteLocationRaw | string;
}>();

const linkProps = computed(() => {
  const isRoute = isObject(props.href);
  if (isRoute) {
    return {
      to: props.href,
    };
  }

  return {
    href: props.href,
  };
});
</script>

<template>
  <component
    :is="isObject(props.href) ? RouterLink : 'a'"
    v-bind="linkProps"
    class="rounded text-blue-600 outline-none outline-offset-2 hover:underline focus-visible:outline-1 focus-visible:outline-indigo-600"
  >
    <slot />
  </component>
</template>
