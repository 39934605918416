import type { ListJs } from "@/common/models";
import type {
  DeleteFilesystemItemsRequest,
  FilesystemDefaultMappingType,
  FilesystemFolderInfo,
  FilesystemJs,
  FilesystemNotificationStateJs,
  FilesystemRootListingJs,
  FilesystemSearchDataJs,
  FolderJs,
  FolderNavJs,
  FolderRowJs,
  InvitationJs,
  ListingJs,
  MoveFilesystemItemsRequest,
  MoveFilesystemItemsResponse,
  RecentJs,
  ResponseObject,
  WorkspaceSecurityJs,
} from "@/generated/models";
import type { WorkspacePermissionType } from "ls/features/share/workspacePermissions";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { type SubInstanceOrganizationPermissionType, toCloudDefaultMappingType } from "ls/features/share/workspaceOrganizationPermissions";
import { type ShareLinkAccessType, toCloudUserViewOnly } from "ls/features/share/workspaceShareLinkAccess";
import { server } from "./server";

const DEFAULT_RECENTS_LIMIT: number = 25;
export async function getRecents(options: { limit?: number } = {}, { signal }: RequestOptions = {}) {
  const params = {
    limit: options.limit ?? DEFAULT_RECENTS_LIMIT,
  };
  const response = await server.get("fs/getRecents", { params, signal });
  return response.data as ListJs<RecentJs>;
}

export async function createRecent(shortcut: string) {
  const formData = objectToFormData({ shortcut });
  const response = await server.post("fs/createRecent", formData);
  return response.data as ResponseObject;
}

export async function getUserWorkspaces({ signal }: RequestOptions = {}) {
  const params = {
    skipEntryCount: true,
  };
  const response = await server.get("fs/getUserWorkspaces", { params, signal });
  return response.data as ListingJs;
}

export async function getUserWorkspacesNotificationState({ signal }: RequestOptions = {}) {
  const response = await server.get("fs/getuserworkspacesnotificationstate", { signal });
  return response.data as FilesystemNotificationStateJs[];
}

export async function getUserWorkspacesForTree({ signal }: RequestOptions = {}) {
  const response = await server.get("fs/getUserWorkspacesForTree", { signal });
  return response.data as ListingJs;
}

export async function getUserOrganizationWorkspaces(options: { organizationId: string }, { signal }: RequestOptions = {}) {
  const params = {
    organizationId: options.organizationId,
  };
  const response = await server.get("fs/getUserOrganizationWorkspaces", { params, signal });
  return response.data as ListingJs;
}

export async function getWorkspace(options: { workspaceId: string }, { signal }: RequestOptions = {}) {
  const params = {
    filesystemId: options.workspaceId,
  };
  const response = await server.get("fs/getWorkspace", { params, signal });
  return response.data as FilesystemJs;
}

export async function getFilesystemRootFolderContents(options: { workspaceId: string }, { signal }: RequestOptions = {}) {
  const params = {
    filesystemId: options.workspaceId,
  };
  const response = await server.get("fs/getFilesystemRootFolderContents", { params, signal });
  return response.data as FilesystemRootListingJs;
}

export async function getFolderNavByShortcutId(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get("fs/getFolderNavByShortcutId", {
    params: {
      shortcut,
    },
    signal,
  });
  return response.data as FolderNavJs;
}

export async function getFolderNavById(folderId: string, { signal }: RequestOptions = {}) {
  const response = await server.get("fs/getFolderNavById", {
    params: {
      folderId,
    },
    signal,
  });
  return response.data as FolderNavJs;
}

export async function getFolderContents(folderId: string, { signal }: RequestOptions = {}) {
  const response = await server.get("fs/getFolderContents", {
    params: {
      folderId,
    },
    signal,
  });
  return response.data as ListingJs;
}

export async function getUserFolders({ signal }: RequestOptions = {}) {
  const response = await server.get("fs/getUserFolders", {
    signal,
  });
  return response.data as FolderJs[];
}

export async function getWorkspaceSecurity(options: { workspaceId: string }, { signal }: RequestOptions = {}) {
  const params = {
    filesystemId: options.workspaceId,
  };
  const response = await server.get("fs/workspaceSecurity", { params, signal });
  return response.data as WorkspaceSecurityJs;
}

interface CreateWorkspaceModel {
  Name: string;
  OrganizationId: string;
  UserViewOnly?: boolean;
}
export async function workspaceCreate(options: { name: string; organizationId: string; userViewOnly?: boolean }) {
  const model: CreateWorkspaceModel = {
    Name: options.name,
    OrganizationId: options.organizationId,
    UserViewOnly: options.userViewOnly,
  };

  const formData = objectToFormData(model);
  const response = await server.post("fs/createfilesystem", formData);

  return response.data as FilesystemFolderInfo;
}

interface WorkspaceRenameModel {
  FilesystemId: string;
  Name: string;
}
export async function renameFilesystem(options: { id: string; name: string }) {
  const model: WorkspaceRenameModel = {
    FilesystemId: options.id,
    Name: options.name,
  };
  const formData = objectToFormData(model);
  const response = await server.post("fs/renameFilesystem", formData);
  return response.data as ResponseObject;
}

export async function favoriteFilesystem(options: { id: string; isFavorite: boolean }) {
  const formData = objectToFormData({ filesystemId: options.id, favorite: options.isFavorite });
  const response = await server.post("fs/favoriteWorkspace", formData);
  return response.data as ResponseObject;
}

export async function archiveFilesystem(options: { id: string; archive: boolean }) {
  const formData = objectToFormData({ filesystemId: options.id, archive: options.archive });
  const response = await server.post("fs/archiveWorkspace", formData);
  return response.data as ResponseObject;
}

export async function changeFilesystemOwner(options: { id: string; newOwnerEmail: string }) {
  const formData = objectToFormData({ filesystemId: options.id, email: options.newOwnerEmail });
  const response = await server.post("fs/updateFilesystemOwner", formData);
  return response.data as FilesystemJs;
}

export interface RenameFolderModel {
  Name: string;
  FolderId: string;
  currentFolderId: string;
}
export async function renameFolder(options: { id: string; name: string; parentFolderId: string }) {
  const model: RenameFolderModel = {
    FolderId: options.id,
    Name: options.name,
    currentFolderId: options.parentFolderId,
  };
  const formData = objectToFormData(model);
  const response = await server.post("fs/renameFolder", formData);
  return response.data as ResponseObject;
}

export interface CreateFolderModel {
  FolderId?: string;
  FilesystemId?: string;
  Name?: string;
}
export async function createFolder(options: { folderId?: string; filesystemId?: string; name?: string }) {
  const model: CreateFolderModel = {
    FolderId: options.folderId,
    FilesystemId: options.filesystemId,
    Name: options.name,
  };
  const formData = objectToFormData(model);
  const response = await server.post("fs/createFolder", formData);
  return response.data as FolderRowJs;
}

export async function deleteFilesystem(filesystemId: string) {
  const formData = objectToFormData({
    filesystemId,
  });

  const response = await server.post("fs/deleteFilesystem", formData);
  return response.data as ResponseObject;
}

export async function deleteFilesystemItems(deleteItems: DeleteFilesystemItemsRequest) {
  const formData = objectToFormData(deleteItems);
  const response = await server.post("fs/deleteFilesystemItems", formData);
  return response.data as ResponseObject;
}

export async function moveFilesystemItems(moveItems: MoveFilesystemItemsRequest) {
  const formData = objectToFormData(moveItems);
  const response = await server.post("fs/moveFilesystemItems", formData);
  return response.data as MoveFilesystemItemsResponse;
}

export interface ShareFilesystemParams {
  filesystemId: string;
  userEmails: string[];
  viewerOnly: boolean;
  ignoreAccepted: boolean;
  ignorePending: boolean;
  assignSeats: boolean;
  inviteMessage?: string;
  recaptchaToken?: string;
}
export interface ShareFilesystemModelBase {
  filesystemId: string;
  userEmails: string;
  viewerOnly: boolean;
  ignoreAccepted: boolean;
  ignorePending: boolean;
  assignSeats: boolean;
}

export type ShareFilesystemModelProtected = ShareFilesystemModelBase & {
  inviteMessage: string;
  recaptchaToken: string;
};

export type ShareFilesystemModel = ShareFilesystemModelBase | ShareFilesystemModelProtected;

export async function shareWorkspace(params: ShareFilesystemParams) {
  const model = {
    filesystemId: params.filesystemId,
    userEmails: params.userEmails.join(","),
    inviteMessage: params.inviteMessage,
    assignSeats: params.assignSeats,
    ignoreAccepted: params.ignoreAccepted,
    ignorePending: params.ignorePending,
    viewerOnly: params.viewerOnly,
    recaptchaToken: params.recaptchaToken,
  } satisfies ShareFilesystemModel;

  const formData = objectToFormData(model);
  // when passing invite message recaptcha-protected endpoint should be used instead
  const url = model.inviteMessage ? "fs/inviteUsersToFilesystem" : "fs/shareFilesystem";
  const response = await server.post(url, formData);

  return response.data as ResponseObject;
}

export interface UnshareFilesystemModel {
  filesystemId: string;
  usersToRemove: string[];
  removeAllUsers: boolean;
}

export async function unshareWorkspace(params: { filesystemId: string; userIds: string[] }) {
  const model: UnshareFilesystemModel = {
    filesystemId: params.filesystemId,
    usersToRemove: params.userIds,
    removeAllUsers: false,
  };

  const formData = objectToFormData(model);
  const response = await server.post("fs/unshareFilesystem", formData);
  return response.data as ResponseObject;
}

export async function joinWorkspace(filesystemId: string) {
  const model = {
    filesystemId,
  };

  const formData = objectToFormData(model);
  const response = await server.post("fs/joinToFilesystem", formData);
  return response.data as ResponseObject;
}

export async function leaveWorkspace(filesystemId: string) {
  const model = {
    filesystemId,
  };

  const formData = objectToFormData(model);
  const response = await server.post("fs/unjoinShare", formData);
  return response.data as ResponseObject;
}

export interface SetFilesystemReviewerModel {
  filesystemId: string;
  userToChange: string;
  isReviewOnly: boolean;
}

export async function workspaceMemberSetPermissions(params: {
  filesystemId: string;
  userEmail: string;
  permissionType: WorkspacePermissionType;
}) {
  const model: SetFilesystemReviewerModel = {
    userToChange: params.userEmail,
    filesystemId: params.filesystemId,
    isReviewOnly: params.permissionType === "view-only",
  };

  const formData = objectToFormData(model);
  const response = await server.post("fs/setReviewerOnly", formData);

  return response.data as ResponseObject;
}

export interface ChangeFilesystemDefaultMappingTypeModel {
  filesystemId: string;
  newMappingType: FilesystemDefaultMappingType;
}

export async function workspaceOrganizationSetPermissions(params: {
  filesystemId: string;
  permissionType: SubInstanceOrganizationPermissionType;
}) {
  const model: ChangeFilesystemDefaultMappingTypeModel = {
    filesystemId: params.filesystemId,
    newMappingType: toCloudDefaultMappingType(params.permissionType),
  };

  const formData = objectToFormData(model);

  const response = await server.post("fs/changeFilesystemDefaultMappingType", formData);
  return response.data as ResponseObject;
}

interface FilesystemSetUserOnlyModel {
  filesystemId: string;
  userOnly: boolean;
}
export async function workspaceSetUserOnly(params: {
  filesystemId: string;
  shareLinkAccessType: ShareLinkAccessType;
}) {
  const model: FilesystemSetUserOnlyModel = {
    filesystemId: params.filesystemId,
    userOnly: toCloudUserViewOnly(params.shareLinkAccessType),
  };

  const formData = objectToFormData(model);

  const response = await server.post("fs/setUserOnly", formData);
  return response.data as ResponseObject;
}

export async function getUserSearchData({ signal }: RequestOptions = {}) {
  const response = await server.get("/fs/getUserSearchData2", { signal });
  return response.data as FilesystemSearchDataJs;
}

export async function getUserInvitations({ signal }: RequestOptions = {}) {
  const response = await server.get("fs/getUserInvitations", { signal });
  return response.data as InvitationJs[];
}

interface FilesystemInvitationRespond {
  filesystemId: string;
  accept: boolean;
}

export async function inviteRespond(params: { filesystemId: string; accept: boolean }) {
  const model: FilesystemInvitationRespond = {
    filesystemId: params.filesystemId,
    accept: params.accept,
  };

  const formData = objectToFormData(model);

  const response = await server.post("fs/inviteRespond", formData);
  return response.data as ResponseObject;
}
