import { createEventHook } from "@vueuse/core";

export function createVoidEventHook<T>() {
  const hook = createEventHook<T>();

  // override trigger to ignore return values from event hook
  function trigger(payload: T) {
    hook.trigger(payload);
  }

  return {
    on: hook.on,
    off: hook.off,
    trigger,
  };
}
