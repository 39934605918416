export const Keys = {
  Space: " ",
  Enter: "Enter",
  Escape: "Escape",
  Backspace: "Backspace",
  Delete: "Delete",
  Alt: "Alt",
  Shift: "Shift",
  Control: "Control",
  Meta: "Meta",

  Comma: "Comma",
  Period: "Period",

  ArrowLeft: "ArrowLeft",
  ArrowUp: "ArrowUp",
  ArrowRight: "ArrowRight",
  ArrowDown: "ArrowDown",

  K: "k",
  L: "l",
  M: "m",

  Home: "Home",
  End: "End",

  PageUp: "PageUp",
  PageDown: "PageDown",

  Tab: "Tab",
  Backquote: "`",
} as const;
