import type { PrototypeViewerParams, ResponseObject, ShortcutCloneJs, ShortcutCreateJs, ShortcutJs, ShortcutPagesResponse, ShortcutRedirectCommentInfoJs, VarsJs } from "@/generated/models";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export async function getPrototypeInfo(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get(`prototype/getPrototypeInfo/${shortcut}`, { signal });
  return response.data as ShortcutJs;
}

export async function getPrototypePages(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get(`prototype/pages/${shortcut}`, { signal });
  return response.data as ShortcutPagesResponse;
}

export function setFeedback(shortcut: string, feedbackEnabled: boolean) {
  const formData = objectToFormData({ feedbackEnabled });
  return server.post(`prototype/setFeedback/${shortcut}`, formData);
}

export async function getViewerParams({ signal }: RequestOptions = {}) {
  const response = await server.get(`prototype/getViewerParams`, { signal });
  return response.data as PrototypeViewerParams;
}

export interface ChangeProjectInfo {
  Name: string;
  Password: string;
  fileData: File | null;
  IsUserOnly: boolean | null;
  PublishNote: string | null;
  PublishNoteRichText: string | null;
}
export async function changePrototypeInfo(
  shortcut: string,
  changedProjectInfo: Partial<ChangeProjectInfo>,
  options: RequestOptions = {},
) {
  const formData = objectToFormData(changedProjectInfo);
  const response = await server.post(`prototype/changePrototypeInfo/${shortcut}`, formData, {
    signal: options.signal,
    onUploadProgress: options.onUploadProgress,
  });

  return response.data as ShortcutJs;
}

export async function getPassword(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get(`/prototype/getPassword/${shortcut}`, { signal });
  return response.data as VarsJs & { Vars: { password: string } };
}

export interface CreateEmptyModel {
  FolderId?: string;
  FilesystemId?: string;
  Name?: string;
}
export async function createEmpty({ folderId, filesystemId, name }: { folderId?: string; filesystemId?: string; name?: string }) {
  const model: CreateEmptyModel = {
    FolderId: folderId,
    FilesystemId: filesystemId,
    Name: name,
  };
  const formData = objectToFormData(model);
  const response = await server.post("prototype/createEmpty", formData);
  return response.data as ShortcutCreateJs;
}

export async function getPrototypeRedirectCommentInfo(shortcut: string) {
  const response = await server.get(`prototype/getPrototypeRedirectCommentInfo/${shortcut}`);
  return response.data as ShortcutRedirectCommentInfoJs;
}

export async function cloneShortcut({ shortcut, recaptchaToken }: { shortcut: string; recaptchaToken?: string }) {
  const formData = objectToFormData({ shortcut, recaptchaToken });
  const response = await server.post("prototype/cloneShortcut", formData);
  return response.data as ShortcutCloneJs;
}

export async function createPublishNote(shortcut: string, publishNote: string) {
  const formData = objectToFormData({ PublishNoteRichText: publishNote });
  const response = await server.post(`/prototype/createPublishNote/${shortcut}`, formData);
  return response.data as ResponseObject;
}
