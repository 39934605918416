<script setup lang="ts">
import { cspNonce } from "@/csp";
import { ConfigProvider } from "radix-vue";
import { computed, defineAsyncComponent } from "vue";
import { useChunkPreloadErrorHandling } from "./chunk-reload";
import { useProvideRecaptchaContext } from "./components/recaptcha/useRecaptchaContext";
import { SimpleToaster } from "./components/simple-toast";
import { SimpleTooltipProvider } from "./components/simple-tooltip";
import ClickthroughUploadProgress from "./features/clickthrough/ClickthroughUploadProgress.vue";
import CommandK from "./features/command-k/CommandK.vue";
import GlobalHubs from "./features/hubs/GlobalHubs.vue";
import { useAuth } from "./features/user/useAuth";
import { useAxureCloudConfig } from "./state/useAxureCloudConfig";
import "./style.scss";

const MiniProfiler = defineAsyncComponent(() => import("./features/mini-profiler/MiniProfiler.vue"));

useProvideRecaptchaContext();
useChunkPreloadErrorHandling();

const config = useAxureCloudConfig();
const allowProfiling = computed(() => {
  return config.value?.AllowProfiling;
});

const { user } = useAuth();
</script>

<template>
  <ConfigProvider :nonce="cspNonce">
    <SimpleTooltipProvider>
      <RouterView />
      <SimpleToaster />
      <CommandK v-if="user" />
      <MiniProfiler v-if="allowProfiling" />
      <ClickthroughUploadProgress v-if="user" />
      <GlobalHubs v-if="user" />
    </SimpleTooltipProvider>
  </ConfigProvider>
</template>
