import type { AccountRowJs, SsoResponse, UserProfileJs } from "@/generated/models";
import { trimEmailDomain } from "@/common/lib/string";

import { simpleHash } from "@/common/utils";

export interface UserProfile {
  email: string;
  profileImageUrl?: string;
  nickname?: string;
}

export interface ChangeAccountInfoModel {
  currentEmail?: string;
  currentPassword: string;
  newEmail?: string;
  newPassword?: string;
}

export function fromUserProfileJs(user: UserProfileJs): UserProfile {
  return {
    email: user.userEmail,
    nickname: user.username,
    profileImageUrl: user.profileImg,
  };
}

export function fromAccountRowJs(user: AccountRowJs): UserProfile {
  return {
    email: user.Email,
    nickname: user.Nickname,
    profileImageUrl: user.ProfileImageUrl,
  };
}

export function fromSsoResponse(user: SsoResponse): UserProfile {
  return {
    email: user.userEmail,
    nickname: user.nickname,
    profileImageUrl: user.profileImageUrl,
  };
}

export function getUserName(email: string, nickname?: string) {
  if (nickname) return nickname;
  return trimEmailDomain(email);
}

const avatarColors = [
  "#e72525",
  "#7eb919",
  "#8d29c9",
  "#37465e",
  "#e48810",
  "#2c70f6",
  "#f70077",
];

export function getUserInitials(name: string) {
  return name.substring(0, 1).toUpperCase();
}

export function getAvatarBackgroundColor(name: string) {
  const hash = simpleHash(name);
  const index = Math.abs(hash % avatarColors.length);
  return avatarColors[index];
}
