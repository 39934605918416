import type { ListingJs } from "@/generated/models";
import type { QueryClient } from "@tanstack/vue-query";
import { queryOptions, skipToken } from "@tanstack/vue-query";
import { getFilesystemRootFolderContents, getFolderContents, getFolderNavById, getUserFolders, getUserInvitations, getUserOrganizationWorkspaces, getUserWorkspaces, getUserWorkspacesForTree, getUserWorkspacesNotificationState, getWorkspace, getWorkspaceSecurity } from "ls/api/cloud/fs";
import { STALE } from "ls/queries/staleTime";
import { queries as fileQueries } from "../files/queries";

export interface FetchContentsErrorCause {
  id: string;
  type: "workspace" | "folder";
}

export function isFetchContentsError(error: Error): error is Error & { cause: FetchContentsErrorCause } {
  if (!error.cause) return false;
  if (typeof error.cause !== "object") return false;
  return "id" in error.cause && "type" in error.cause;
}

export const queries = {
  workspaces: () => ["workspaces"] as const,
  workspacesNotificationState: () =>
    queryOptions({
      queryKey: [...queries.workspaces(), "notificationState"] as const,
      queryFn: ({ signal }) => getUserWorkspacesNotificationState({ signal }),
    }),
  workspace: (workspaceId: string) => [...queries.workspaces(), workspaceId] as const,
  list: () =>
    queryOptions({
      queryKey: [...queries.workspaces(), "list"] as const,
      queryFn: ({ signal }) => getUserWorkspaces({ signal }),
    }),
  tree: () =>
    queryOptions({
      queryKey: [...queries.workspaces(), "tree"] as const,
      queryFn: ({ signal }) => getUserWorkspacesForTree({ signal }),
    }),
  listOrganization: (organizationId: string) =>
    queryOptions({
      queryKey: [...queries.workspaces(), "list", { organizationId }] as const,
      queryFn: ({ signal }) => getUserOrganizationWorkspaces({ organizationId }, { signal }),
    }),
  contents: (workspaceId: string, queryClient: QueryClient) =>
    queryOptions({
      queryKey: [...queries.workspace(workspaceId), "contents"] as const,
      queryFn: async ({ signal }) => {
        try {
          const contents = await getFilesystemRootFolderContents({ workspaceId }, { signal });

          // pre-populate data for project info query
          for (const shortcutJs of contents.Listing.Shortcuts) {
            queryClient.setQueryData(fileQueries.info(shortcutJs.Shortcut).queryKey, shortcutJs);
          }

          return contents;
        } catch (error) {
          if (error instanceof Error) {
            const cause: FetchContentsErrorCause = {
              id: workspaceId,
              type: "workspace",
            };
            error.cause = cause;
          }
          throw error;
        }
      },
    }),
  info: (workspaceId: string | undefined, queryClient?: QueryClient) =>
    queryOptions({
      queryKey: [...queries.workspace(workspaceId ?? ""), "info"] as const,
      queryFn: workspaceId
        ? ({ signal }) => {
            return getWorkspace({ workspaceId }, { signal });
          }
        : skipToken,
      placeholderData: () => {
        if (!queryClient) return;
        const queryKey = queries.list().queryKey;
        return queryClient.getQueryData(queryKey)?.Filesystems.find(d => d.FilesystemId === workspaceId);
      },
      staleTime: STALE.SECONDS.FIFTEEN,
    }),
  activity: (workspaceId: string) => [...queries.workspace(workspaceId), "activity"] as const,
  security: (workspaceId: string) =>
    queryOptions({
      queryKey: [...queries.workspace(workspaceId), "security"] as const,
      queryFn: ({ signal }) => getWorkspaceSecurity({ workspaceId }, { signal }),
    }),
  folders: () => ["folders"] as const,
  folder: (folderId: string) => [...queries.folders(), folderId] as const,
  folderLocation: (folderId: string) =>
    queryOptions({
      queryKey: [...queries.folder(folderId), "location"] as const,
      queryFn: ({ signal }) => {
        return getFolderNavById(folderId, { signal });
      },
      staleTime: STALE.SECONDS.FIFTEEN,
    }),
  folderContents: (folderId: string, queryClient: QueryClient) =>
    queryOptions({
      queryKey: [...queries.folder(folderId)] as const,
      queryFn: async ({ signal }) => {
        try {
          const contents = await getFolderContents(folderId, { signal });

          // pre-populate data for project info query
          for (const shortcutJs of contents.Shortcuts) {
            queryClient.setQueryData(fileQueries.info(shortcutJs.Shortcut).queryKey, shortcutJs);
          }

          return contents;
        } catch (error) {
          if (error instanceof Error) {
            const cause: FetchContentsErrorCause = {
              id: folderId,
              type: "folder",
            };
            error.cause = cause;
          }
          throw error;
        }
      },
    }),
  foldersList: () =>
    queryOptions({
      queryKey: [...queries.folders(), "list"] as const,
      queryFn: ({ signal }) => getUserFolders({ signal }),
    }),

  invitations: () => ["invitations"] as const,
  openInvitations: () =>
    queryOptions({
      queryKey: [...queries.invitations(), "list"] as const,
      queryFn: ({ signal }) => getUserInvitations({ signal }),
      staleTime: STALE.SECONDS.FIFTEEN,
    }),
};

export function folderNodesFromContents(contents: ListingJs) {
  return [
    ...contents.Folders,
    ...contents.Shortcuts,
  ];
}
