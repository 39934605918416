import type { RouteLocationNormalized } from "vue-router";

interface CreateDiskoUrlOptions {
  to?: RouteLocationNormalized;
  params?: URLSearchParams;
}

export function createDiskoUrl(shortcut: string, options?: CreateDiskoUrlOptions) {
  const { to, params: extraParams } = options ?? {};

  const base = import.meta.env.PROD ? window.location.origin : import.meta.env.VITE_APP_DISKO_URL;
  const url = new URL(`/file/${shortcut.toLowerCase()}`, base);
  if (to) {
    for (const [name, value] of Object.entries(to.query)) {
      if (typeof value === "string") {
        url.searchParams.set(name, value);
      }
    }
    url.hash = to.hash;
  }

  if (extraParams) {
    for (const [name, value] of extraParams.entries()) {
      url.searchParams.append(name, value);
    }
  }

  return url.toString();
}
