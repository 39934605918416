import type { LocationQuery, RouteParams } from "vue-router";

export function getRouteParam(params: RouteParams, name: string): string | undefined {
  const param = params[name];
  return Array.isArray(param) ? param[0] : param;
}

export function getQueryParam(query: LocationQuery, name: string): string | undefined {
  const param = query[name];
  const queryParam = Array.isArray(param) ? param[0] : param;
  return queryParam ?? undefined;
}
