import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { queries } from "./queries";
import { useAccountService } from "./useAccountService";
import { fromSsoResponse } from "./utils";

export function useCurrentUserInfo() {
  const { data: userInfo } = useQuery(queries.info());
  return userInfo;
}

export function useAuth() {
  const { user, isAuthenticated, error, login, logout, updateUserProfileName, uploadUserProfileImg, deleteUserProfileImg, changeAccountInfo, forgotPassword } = useAccountService();
  const profile = computed(() => {
    if (!user.value) return;
    return fromSsoResponse(user.value);
  });

  return {
    user,
    profile,
    isAuthenticated,
    error,
    login,
    logout,
    updateUserProfileName,
    uploadUserProfileImg,
    deleteUserProfileImg,
    changeAccountInfo,
    forgotPassword,
  };
}
