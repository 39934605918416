import type { ListJs, ResponseObject, SsoResponse, TimeZoneJs } from "@/generated/models";
import type { DoLoginModel } from "@/services/models/account";
import type { RequestOptions } from "ls/api/common/server";
import type { RouteLocationNormalized } from "vue-router";
import type { ChangeAccountInfoModel } from "./utils";
import { authParamName, redirectParamName } from "@/common/axshare/routing";
import { testSameOrigin } from "@/common/lib/browser";
import { objectToFormData } from "@/services/utils/formData";
import Axios, { type AxiosInstance } from "axios";
import { setupServer } from "ls/api/common/server";
import { wheneverOnceAsync } from "ls/composables/wheneverOnceAsync";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { ref, shallowRef, watch } from "vue";

function getTimeZoneFormData(timeZoneId?: string): FormData {
  const timeZone = timeZoneId || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const baseUtcOffsetMinutes = -(new Date().getTimezoneOffset());
  return objectToFormData({ timeZone, baseUtcOffsetMinutes });
}

function authTokenStorage() {
  let token: string | undefined;

  function get(): string | undefined {
    return token;
  }

  function set(authToken: string) {
    token = authToken;
  }

  function clear() {
    token = undefined;
  }

  return { get, set, clear };
}

const authToken = authTokenStorage();

export function useAxureCloudAccountService() {
  const config = useAxureCloudConfig();

  const accountServerRef = shallowRef<AxiosInstance>();
  const appServerRef = shallowRef<AxiosInstance>();

  const appServerUrl = ref<string>();
  const accountServerUrl = ref<string>();
  const clientAppUrl = ref<string>();

  function createServerInstance(baseUrl: string) {
    const instance = Axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });

    setupServer(instance);

    instance.interceptors.request.use(request => {
      const token = authToken.get();
      if (token) {
        request.headers.set("Authorization", token, true);
      }
      return request;
    });

    return instance;
  }

  watch(config, value => {
    if (!value) {
      accountServerRef.value = undefined;
      appServerUrl.value = undefined;
      accountServerUrl.value = undefined;
      clientAppUrl.value = undefined;
      return;
    }

    appServerUrl.value = value.AxShareHostSecureUrl;
    accountServerUrl.value = value.AccountServiceSecureUrl;
    clientAppUrl.value = value.AxShareClientUrl;
  }, { immediate: true });

  watch(appServerUrl, url => {
    if (!url) return;

    // server already created, reconfigure with new service url
    if (appServerRef.value) {
      appServerRef.value.defaults.baseURL = url;
      return;
    }
    appServerRef.value = createServerInstance(url);
  });

  watch(accountServerUrl, url => {
    if (!url) return;

    // server already created, reconfigure with new service url
    if (accountServerRef.value) {
      accountServerRef.value.defaults.baseURL = url;
      return;
    }
    accountServerRef.value = createServerInstance(url);
  });

  async function ensureAccountServer() {
    return await wheneverOnceAsync(() => accountServerRef.value);
  }

  async function ensureAppServer() {
    return await wheneverOnceAsync(() => appServerRef.value);
  }

  async function auth() {
    const server = await ensureAppServer();
    const response = await server.post("user/oauth2");
    const sso = response.data as SsoResponse;

    if (sso.success && sso.authToken) {
      authToken.set(sso.authToken);
    }

    return sso;
  }

  async function login(email: string, password: string, redirect?: string) {
    const server = await ensureAccountServer();
    const model: DoLoginModel = {
      loginEmail: email.trim(),
      loginPassword: password,
      altPassword: "",
      passwordBlank: !password,
      accountService: false,
      clearPass: true,
      staySignedIn: true,
      redirect,
    };

    // ASK: can we use our existing "exec" call to enable support of "redirects"?
    const response = await server.post("user/doSignIn", objectToFormData(model));
    const sso = response.data as SsoResponse;

    if (sso.success) {
      if (sso.authToken) {
        authToken.set(sso.authToken);
      }

      if (sso.redirecturl) {
        window.location.href = sso.redirecturl;
        return;
      }
    }

    return sso;
  }

  async function logout() {
    const server = await ensureAccountServer();
    const response = await server.post("user/logout?isAjax=true");

    authToken.clear();

    return response;
  }

  async function updateUserProfileName(name: string) {
    const server = await ensureAccountServer();
    const formData = objectToFormData({ name });
    const response = await server.post("user/updateUserProfileName", formData);
    return response.data as ResponseObject;
  }

  async function uploadUserProfileImg(fileToUpload: File) {
    const server = await ensureAccountServer();
    const formData = objectToFormData({ fileToUpload });
    const response = await server.post("user/uploadUserProfileImg", formData);
    return response.data as ResponseObject & { imgUrl: string };
  }

  async function deleteUserProfileImg() {
    const server = await ensureAccountServer();
    const response = await server.post("user/deleteUserProfileImg");
    return response.data as ResponseObject;
  }

  async function changeAccountInfo(model: ChangeAccountInfoModel) {
    const server = await ensureAccountServer();
    const formData = objectToFormData(model);
    const response = await server.post("user/changeAccountInfo", formData);
    const data = response.data as ResponseObject & { authToken: string };
    if (data.success && data.authToken) {
      await refreshAuth(data.authToken);
    }
    return data;
  }

  async function refreshAuth(token: string) {
    authToken.set(token);
    const formData = objectToFormData({ token });
    if (await isSameAsApiHost()) {
      await ensureAccountServer().then(s => s.post("user/refreshAuth", formData));
    } else {
      await Promise.all([
        ensureAccountServer().then(s => s.post("user/refreshAuth", formData)),
        ensureAppServer().then(s => s.post("user/refreshAuth", formData)),
      ]);
    }
  }

  async function forgotPassword(email: string, target: string, redirect?: string) {
    const server = await ensureAccountServer();
    const response = await server.post("user/forgotPassword", objectToFormData({ email, target, redirect }));
    return response.data as ResponseObject;
  }

  async function getTimeZones({ signal }: RequestOptions = {}) {
    const server = await ensureAccountServer();
    const response = await server.get("user/getTimeZones", { signal });
    return response.data as ListJs<TimeZoneJs>;
  }

  async function setUserTimeZone(timeZoneId?: string, { signal }: RequestOptions = {}) {
    const server = await ensureAccountServer();
    const formData: FormData = getTimeZoneFormData(timeZoneId);
    const response = await server.post("user/setLocalTimeZone", formData, { signal });
    return response.data as ResponseObject;
  }

  async function isSameAsApiHost() {
    await ensureAccountServer();
    return !accountServerUrl.value || !appServerUrl.value || testSameOrigin(accountServerUrl.value, appServerUrl.value);
  }

  async function getLoginFullUrl(to?: RouteLocationNormalized) {
    // await ensureAccountServer();
    const fullLoginUrl = new URL(await getLoginBaseUrl());

    const afterAuthRedirectUrl = getAfterAuthRedirectUrl(to);
    fullLoginUrl.searchParams.append(redirectParamName, afterAuthRedirectUrl);
    return fullLoginUrl.href;
  }

  async function getLoginBaseUrl() {
    if (await isSameAsApiHost()) {
      if (clientAppUrl.value) {
        let appUrl = clientAppUrl.value;
        if (!appUrl.endsWith("/")) {
          appUrl += "/";
        }
        return `${appUrl}login`;
      }

      const absoluteLoginURL = new URL("/app/login", window.location.origin);
      return absoluteLoginURL.href;
    }

    return `${accountServerUrl.value}/app/login`;
  }

  function getAfterAuthRedirectUrl(to?: RouteLocationNormalized) {
    const clientAfterAuthUrl = new URL("/app/login", window.location.origin);
    clientAfterAuthUrl.searchParams.append(authParamName, "true");

    const redirectParam = to?.query[redirectParamName];
    let redirect = Array.isArray(redirectParam) ? redirectParam[0] : redirectParam;
    if (!redirect && to && to.fullPath !== undefined) {
      redirect = to.fullPath.split("#")[0];
    }
    clientAfterAuthUrl.searchParams.append(redirectParamName, redirect || "");

    const appServerAuthUrl = new URL(`${appServerUrl.value}/user/axureauth`);
    appServerAuthUrl.searchParams.append(redirectParamName, clientAfterAuthUrl.href);
    return appServerAuthUrl.href;
  }

  return {
    auth,
    login,
    logout,
    updateUserProfileName,
    uploadUserProfileImg,
    deleteUserProfileImg,
    changeAccountInfo,
    forgotPassword,
    getTimeZones,
    setUserTimeZone,
    isSameAsApiHost,
    getLoginFullUrl,
  };
}
