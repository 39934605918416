import type { ShortcutJs } from "@/generated/models";
import type { AxiosProgressEvent } from "axios";
import type { RefOrGetter } from "ls/common/types";
import { asError, uuid } from "@/common/lib";
import { addArtboard } from "ls/api/cloud/expo";
import { computed, readonly, ref, toValue } from "vue";

type ClickthroughUploadBatchSource = "web" | "desktop";
type ClickthroughUploadStatus = "idle" | "exporting" | "pending" | "success" | "error" | "canceled";

export interface ClickthroughUploadBatch {
  id: string;
  shortcut: string;
  shortcutName: string;
  created: Date;
  status: ClickthroughUploadStatus;
  source: ClickthroughUploadBatchSource;
  progress?: AxiosProgressEvent;
  abort: AbortController["abort"];
  signal: AbortSignal;
  error?: Error;
}

const batches = ref(new Map<string, ClickthroughUploadBatch>());
const batchFiles = ref(new WeakMap<ClickthroughUploadBatch, File[]>());

export function useBatchFiles(batch: RefOrGetter<ClickthroughUploadBatch>) {
  return computed(() => batchFiles.value.get(toValue(batch)));
}

export function useClickthroughUploader() {
  async function newUpload(file: ShortcutJs, source: ClickthroughUploadBatchSource, files: File[]) {
    const controller = new AbortController();
    const batchId = uuid();
    const batch: ClickthroughUploadBatch = {
      id: batchId,
      source,
      created: new Date(),
      status: "idle",
      shortcut: file.Shortcut,
      shortcutName: file.Name,
      abort: controller.abort,
      signal: controller.signal,
    };
    batchFiles.value.set(batch, files);
    batches.value.set(batchId, batch);

    await upload(batch);
  }

  function update(batchId: string, update: Partial<ClickthroughUploadBatch>) {
    const batch = batches.value.get(batchId);
    if (!batch) return;

    const newBatch = {
      ...batch,
      ...update,
    };

    batches.value.set(batchId, newBatch);

    // make sure to keep files around in weakmap
    const files = batchFiles.value.get(batch);
    if (files) {
      batchFiles.value.set(newBatch, files);
    }
  }

  async function upload(batch: ClickthroughUploadBatch) {
    const files = batchFiles.value.get(batch);
    if (!files) {
      throw new Error("Failed to start batch upload, no files in batch.");
    }

    try {
      update(batch.id, { status: "pending" });
      await addArtboard({ shortcut: batch.shortcut, files, skipPublishNotes: true }, {
        signal: batch.signal,
        onUploadProgress(progressEvent) {
          update(batch.id, { progress: progressEvent });
        },
      });
      update(batch.id, { status: "success" });
    } catch (error) {
      update(batch.id, {
        status: "error",
        error: asError(error),
      });
    }
  }

  function dismiss(batchId: string) {
    const batch = batches.value.get(batchId);
    if (!batch) return;

    batches.value.delete(batchId);
  }

  async function newWebUpload(shortcut: ShortcutJs, files: File[]) {
    await newUpload(shortcut, "web", files);
  }

  return {
    batches: readonly(batches),
    newWebUpload,
    dismiss,
  };
}
