<script setup lang="ts">
import type { ComboboxItemEmits, ComboboxItemProps } from "radix-vue";
import { ComboboxItem, useForwardPropsEmits } from "radix-vue";

export type CommandItemProps = ComboboxItemProps;

const props = defineProps<ComboboxItemProps>();
const emits = defineEmits<ComboboxItemEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
  <ComboboxItem
    v-bind="forwarded"
    class="relative flex flex-row flex-nowrap items-center gap-2 whitespace-nowrap rounded outline-none data-[highlighted]:bg-slate-200 data-[disabled]:text-slate-500"
  >
    <slot />
  </ComboboxItem>
</template>
