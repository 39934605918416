import { type MaybeRefOrGetter, onBeforeMount, onBeforeUnmount, toValue, watch } from "vue";
import {
  type HubName,
  type SignalRHubListeners,
  useAxureCloudHubsConnection,
} from "./useAxureCloudPushNotificationsConnection";

export interface UseAxureCloudHubOptions {
  groupName?: MaybeRefOrGetter<string>;
  listeners: SignalRHubListeners;
}

export function useAxureCloudHub(name: HubName, options: UseAxureCloudHubOptions) {
  const connection = useAxureCloudHubsConnection();

  watch(() => toValue(options.groupName), async (oldGroup, newGroup) => {
    if (oldGroup) await connection.removeFromGroup(name, oldGroup);
    if (newGroup) await connection.addToGroup(name, newGroup);
  }, { immediate: true });

  onBeforeMount(async () => {
    await connection.connectToHub(name);
    await connection.addHubListeners(name, options.listeners);
    if (options.groupName) {
      await connection.addToGroup(name, toValue(options.groupName));
    }
  });

  onBeforeUnmount(async () => {
    await connection.removeHubListeners(name, options.listeners);
    if (options.groupName) {
      await connection.removeFromGroup(name, toValue(options.groupName));
    }
  });
}
