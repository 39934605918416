import { isApple } from "./lib/browser";
import { Keys } from "./lib/keyboard";

export interface KeyboardShortcut {
  shortcut: string;
  key: string;
  code: string;
  modifiers: KeyModifier[];
  label: string;
  isPressed: (event: KeyboardEvent) => boolean;
}

const systemModifiers = [
  Keys.Meta,
  Keys.Control,
  Keys.Alt,
  Keys.Shift,
];

const keyboardEventModifierKeys = {
  [Keys.Meta]: "metaKey",
  [Keys.Control]: "ctrlKey",
  [Keys.Alt]: "altKey",
  [Keys.Shift]: "shiftKey",
} as const;

type KeyModifier = typeof systemModifiers[number];

export function anyPressed(event: KeyboardEvent, shortcuts: KeyboardShortcut[]) {
  for (const s of shortcuts) {
    if (s.isPressed(event)) return true;
  }
  return false;
}

function toIndividualKeys(shortcut: string) {
  return shortcut.split(/[+_-]/g).map(i => i.trim());
}

function getShortcutDescriptor(shortcut: string) {
  const allKeys = toIndividualKeys(shortcut);
  const keys = allKeys.filter(key => !(systemModifiers as string[]).includes(key));
  const modifiers = allKeys.filter(k => (systemModifiers as string[]).includes(k)) as KeyModifier[];

  const key = keys[0];
  if (!key) {
    throw new Error(`Incorrect shortcut '${shortcut}': shortcut key is required`);
  }
  if (keys.length > 1) {
    throw new Error(`Incorrect shortcut '${shortcut}': Multi-key shortcuts aren't supported`);
  }

  return {
    key: key.length === 1 ? key.toLowerCase() : key,
    code: key.length === 1 ? `Key${key.toUpperCase()}` : key,
    modifiers,
  };
}

function normalize(shortcut: string) {
  const fixedMod = shortcut.replaceAll("Mod", isApple ? Keys.Meta : Keys.Control);
  const { key, modifiers } = getShortcutDescriptor(fixedMod);

  return (modifiers as string[]).concat([key]).join("-");
}

function toLabel(shortcut: string) {
  let label = shortcut
    .replaceAll(Keys.ArrowLeft, "←")
    .replaceAll(Keys.ArrowUp, "↑")
    .replaceAll(Keys.ArrowRight, "→")
    .replaceAll(Keys.ArrowDown, "↓")
    .replaceAll(Keys.Comma, ",")
    .replaceAll(Keys.Period, ".")
    .replaceAll("Mod", isApple ? Keys.Meta : Keys.Control);

  if (isApple) {
    label = label
      .replaceAll("-", "")
      .replaceAll("+", "")
      .replaceAll(Keys.Control, "⌃")
      .replaceAll(Keys.Meta, "⌘")
      .replaceAll(Keys.Alt, "⌥")
      .replaceAll(Keys.Shift, "⇧");
  } else {
    label = label
      .replaceAll("-", "+")
      .replaceAll(Keys.Control, "Ctrl")
      .replaceAll(Keys.Alt, "Alt")
      .replaceAll(Keys.Shift, "Shift");
  }

  return label;
}

interface ShortcutDescriptor {
  key: string;
  code: string;
  modifiers: KeyModifier[];
}

function exactGuard(event: KeyboardEvent, modifiers: KeyModifier[]) {
  return systemModifiers.some(m => {
    const prop = keyboardEventModifierKeys[m];
    return event[prop] && !modifiers.includes(m);
  });
}

function allModifiersPressed(event: KeyboardEvent, modifiers: KeyModifier[]) {
  return modifiers.every(m => {
    const prop = keyboardEventModifierKeys[m];
    return event[prop];
  });
}

function createIsPressed({ code, modifiers }: ShortcutDescriptor) {
  return (e: KeyboardEvent) => {
    if (code !== e.code) return false;
    if (exactGuard(e, modifiers)) return false;
    return allModifiersPressed(e, modifiers);
  };
}

export function createKeyboardShortcut(shortcut: string): KeyboardShortcut {
  const normalized = normalize(shortcut);
  const label = toLabel(shortcut);
  const descriptor = getShortcutDescriptor(normalized);

  return {
    shortcut: normalized,
    key: descriptor.key,
    code: descriptor.code,
    modifiers: descriptor.modifiers,
    label,
    isPressed: createIsPressed(descriptor),
  };
}

export const ModK = createKeyboardShortcut("Mod-K");
