<script setup lang="ts">
import { secondsToMilliseconds } from "date-fns";
import { ToastProvider, ToastRoot, ToastViewport } from "radix-vue";
import SimpleToast from "./SimpleToast.vue";
import { useToast } from "./useToast";

const { toasts } = useToast();
const toastDuration = secondsToMilliseconds(3);
</script>

<template>
  <ToastProvider :duration="toastDuration">
    <div class="fixed inset-x-0 bottom-20 z-10 grid items-end justify-items-center">
      <div class="absolute [&_[role=region]]:flex">
        <ToastRoot
          v-for="[id, toast] in toasts"
          :key="id"
          as-child
          v-bind="toast.props"
          @update:open="toast.props.onOpenChange"
        >
          <SimpleToast :toast="toast" />
        </ToastRoot>
        <ToastViewport class="flex flex-col gap-2" />
      </div>
    </div>
  </ToastProvider>
</template>
