<script setup lang="ts">
import { LoaderCircleIcon, SearchIcon, XIcon } from "lucide-vue-next";
import { ComboboxCancel, ComboboxInput, type ComboboxInputProps, useForwardProps } from "radix-vue";
import SimpleButton from "../simple-button/SimpleButton.vue";
import { useCommandContext } from "./context";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<ComboboxInputProps>();

const emit = defineEmits<{
  close: [];
}>();

const forwardedProps = useForwardProps(props);

const { isLoading } = useCommandContext();
</script>

<template>
  <div class="flex items-center border-b border-slate-300 px-3">
    <component
      :is="isLoading ? LoaderCircleIcon : SearchIcon"
      class="mr-2 size-4 shrink-0 text-slate-600 opacity-50"
      :class="{
        'animate-spin': isLoading,
      }"
    />
    <ComboboxInput
      v-bind="{ ...forwardedProps, ...$attrs }"
      auto-focus
      class="flex h-11 w-full bg-transparent py-3 text-sm outline-none placeholder:text-slate-500 disabled:cursor-not-allowed disabled:opacity-50"
    />

    <ComboboxCancel as-child>
      <SimpleButton class="block sm:hidden" size="icon" variant="ghost" aria-label="Cancel" @click="emit('close')">
        <XIcon class="size-4 text-slate-600" aria-hidden="true" />
      </SimpleButton>
    </ComboboxCancel>

    <ComboboxCancel as-child>
      <button class="hidden sm:inline-flex" aria-label="Close" @click="emit('close')">
        <kbd>ESC</kbd>
      </button>
    </ComboboxCancel>
  </div>
</template>
